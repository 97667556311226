import { supabase } from '../lib/supabase';
import { projectDetailViewName } from '../lib/environment';

console.log (projectDetailViewName)

export async function fetchProjectData({ 
  slug,
  setProject,
  setPillars,
  setToggles,
  setLoading,
  setError 
}) {
  try {
    setLoading(true);
    setError(false);

    const { data: projectData, error: projectError } = await supabase
    .from(projectDetailViewName)
    .select(`
      id,
      slug,
      logo,
      title,
      subtitle,
      tagline,
      overview,
      full_image_url,
      prototype_url,
      conclusion_quote,
      visual_elements_url,
      is_published,
      toggles,
      project_content
      `)
      .eq('slug', slug)
      .single();

    if (projectError) throw projectError;
    if (!projectData) {
      setError(true);
      return;
    }

    setProject(projectData);

    // Handle project_content as JSON array
    const projectPillars = projectData.project_content
      ?.filter(content => content.content_type === 'pillar' && content.is_published)
      .sort((a, b) => a.order_num - b.order_num) || [];
    setPillars(projectPillars);

    // Handle toggles as JSON array
    const projectToggles = projectData.toggles
      ?.sort((a, b) => a.order_num - b.order_num) || [];
    setToggles(projectToggles);

  } catch (error) {
    console.error('Error fetching project:', error);
    setError(true);
  } finally {
    setLoading(false);
  }
}
