const contentEnv = import.meta.env.VITE_CONTENT_ENV;

let projectViewName;
let projectDetailViewName;

if (contentEnv === 'DEV') {
  projectViewName = 'dev_published_projects_by_order';
  projectDetailViewName = 'dev_published_project_detail_by_order';
} else if (contentEnv === 'PROD') {
  projectViewName = 'live_projects_by_order';
  projectDetailViewName = 'live_project_detail_by_order';
} else {
  throw new Error(`Invalid VITE_CONTENT_ENV value: ${contentEnv}`);
}

export { projectViewName, projectDetailViewName };